/* General Body Styling */
body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  background-color: #f4f7f6;
  color: #333;
}

/* Main App Container */
.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  text-align: center;
  position: relative;
  overflow-y: auto;
}

/* Content Card Styling */
.content-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}

h1 {
  font-size: 2.5em;
  color: #2c3e50;
  margin-bottom: 20px;
}

h2 {
  font-size: 2em;
  color: #34495e;
  margin-top: 30px;
  margin-bottom: 15px;
}

p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #4d4d4d;
}

/* Link Styling */
a {
  color: #1abc9c;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #16a085;
}

/* Responsive Design */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }
}

/* Language Selector Specific Styling */
.language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  gap: 15px;
}

.language-option {
  cursor: pointer;
  font-size: 1em;
  color: #2c3e50;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
}

.language-option:hover,
.language-option.active {
  color: #1abc9c;
  transform: scale(1.1);
}
